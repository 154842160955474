import React from "react";

const TermsAndConditions = ({ darkTheme }) => {
  return (
    <div
      id="terms-policy"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Terms &amp; Policy
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <p>
              Data engineers are responsible for designing, implementing, and
              maintaining efficient and scalable data processing pipelines. All
              data processing activities should adhere to best practices for
              performance, reliability, and security. Data engineers must ensure
              that data is ingested, transformed, and stored accurately and
              efficiently, meeting the requirements of downstream consumers
            </p>
            <h3 className={"mb-3 mt-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Terms of Use
            </h3>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part I – Information Nitin Jain collects and controls
            </h5>
            <p>
              Nitin Jain, as the operator of this service, collects and controls
              certain information as outlined in this section. We may collect
              personal information such as your name, email address, and other
              contact details when you interact with our platform. Additionally,
              we may gather usage data and analytics to improve our services and
              user experience. Rest assured, we prioritize the security and
              confidentiality of your information
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part II – Information that Nitin Jain processes on your behalf
            </h5>
            <p>
              In certain instances, Nitin Jain may process information on your
              behalf, acting as a data processor. This could include processing
              data for personalized services or fulfilling specific requests
              made by you. We adhere to all relevant data protection regulations
              and only process information according to your instructions and
              consent.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part III – General
            </h5>
            <p>
              These terms and conditions govern your use of our platform. By
              accessing or using our services, you agree to abide by these
              terms. We reserve the right to update or modify these terms at any
              time without prior notice. It is your responsibility to review
              these terms periodically for any changes. Your continued use of
              the service after such modifications will constitute
              acknowledgment and acceptance of the modified terms.
            </p>
            <h3 className={"mb-3 mt-4 " + (darkTheme ? "text-white" : "")}>
              Privacy Policy
            </h3>
            <p>
              Our Privacy Policy outlines how we collect, use, and protect your
              personal information. We are committed to safeguarding your
              privacy and ensuring the security of your data. By using our
              services, you consent to the collection and use of information as
              described in our Privacy Policy. We may use cookies and similar
              technologies to enhance your browsing experience and provide
              personalized content. Your use of our services constitutes
              acceptance of our Privacy Policy.
            </p>
            <ol className="lh-lg">
              <li>
                Information Collection: We collect various types of information,
                including personal and non-personal data, to provide and improve
                our services.
              </li>
              <li>
                Data Processing Purpose: The data collected is processed for
                specific purposes such as analytics, system maintenance, and
                improving user experience.
              </li>
              <li>
                Data Protection Measures: We implement industry-standard
                security measures to protect the confidentiality, integrity, and
                availability of the data we collect and process.
              </li>
              <li>
                Third-party Sharing: We may share data with trusted third-party
                service providers for purposes such as data storage, analysis,
                or processing, but only to the extent necessary to fulfill these
                purposes.
              </li>
              <li>
                Data Retention: We retain data for as long as necessary to
                fulfill the purposes for which it was collected, and in
                compliance with applicable laws and regulations.
              </li>
              <li>
                User Rights: Users have the right to access, correct, or delete
                their personal data held by us. Requests for such actions can be
                made through designated channels outlined in this policy.
              </li>
              <li>
                Cookies and Tracking Technologies: We may use cookies and
                similar technologies to collect data about user interactions
                with our services, for purposes such as analytics and
                personalized content delivery.
              </li>
              <li>
                Updates to Privacy Policy: We may update this privacy policy
                from time to time to reflect changes in our practices or legal
                requirements. Users will be notified of any material changes and
                are encouraged to review the policy periodically.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
