import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // services details
  const services = [
    {
      name: "Data Engineering",
      desc: "With a strong foundation in data modeling, ETL processes, and scalable infrastructure deployment, I consistently deliver robust solutions that drive data-driven decision-making.",
      icon: "fas fa-magnifying-glass-chart",
    },
    {
      name: "SQL",
      desc: "Proficient in SQL, I adeptly design and optimize database queries, ensuring efficient data retrieval and manipulation to support informed decision-making processes.",
      icon: "fas fa-database",
    },
    {
      name: "Python",
      desc: "With a comprehensive grasp of Python, I skillfully develop scalable solutions, leveraging its versatility and rich ecosystem to tackle diverse challenges in data engineering and beyond.",
      icon: "fa-brands fa-python",
    },
    {
      name: "AI/ML",
      desc: "With a proven track record of implementing AI/ML solutions in impactful projects, I leverage advanced algorithms and data-driven insights to tackle complex problems, driving innovation and delivering value.",
      icon: "fas fa-brain",
    },
    {
      name: "Terraform",
      desc: "Proficient in Terraform, I've successfully orchestrated infrastructure at scale in challenging projects, demonstrating expertise in automating deployment and managing cloud resources efficiently to solve critical problems.",
      icon: "fas fa-t",
    },
    {
      name: "Shell",
      desc: "Mastering shell scripting, I've spearheaded critical projects, adeptly automating tasks and solving complex problems with efficiency and precision in diverse computing environments.",
      icon: "fas fa-solid fa-file-code",
    },
    {
      name: "Data modeling",
      desc: "Proficient in Data Modeling, I excel in designing robust data architectures that power insightful analytics and drive informed decision-making across diverse projects.",
      icon: "fa-solid fa-server",
    },
    {
      name: "Cloud engineer",
      desc: "Adept in cloud engineering, I leverage advanced technologies to architect scalable and resilient cloud infrastructures, enabling seamless deployment and optimal performance across diverse projects.",
      icon: "fas fa-solid fa-cloud-arrow-up",
    },
  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Services
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            What I Do?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {service.desc}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
