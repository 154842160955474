import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const experienceDetails = [
    {
      yearRange: "August 2022 - Present",
      title: "Data Engineer - Lead",
      place: "SevenRooms",
      desc: [
        "Architected and constructed the Data infrastructure on GCP, facilitating seamless data management and analytics.",
        "Formulated and implemented rigorous Data security and Governance policies to uphold compliance and data integrity.",
        "Established comprehensive monitoring mechanisms, reducing operational costs by $50K per month while enhancing user activity tracking and auditing.",
        "Spearheaded proactive data quality checks, employing Monte Carlo simulations to fortify data reliability and preempt potential issues.",
        "Deployed CI/CD pipelines via GitHub"
      ],
    },
    {
      yearRange: "January 2020 - September 2022",
      title: "Data Engineer Manager",
      place: "AppFolio, Inc.",
      desc: [
        "Spearheaded the transition to real-time data processing for our production data and business services by leveraging cutting-edge streaming services such as Kubernetes (K8s), Kafka, and Debezium.",
        "Led the transformation of our legacy and current systems into Infrastructure as Code (IaaC) using Terraform, ensuring seamless scalability and reproducibility.",
        "Orchestrated the implementation of batch processing pipelines for product and business services utilizing Apache Airflow, integrating monitoring capabilities with Datadog and Slack for enhanced visibility and communication.",
      ],
    },
    {
      yearRange: "November 2017 - January 2020",
      title: "Data Warehouse Engineer II",
      place: "MINDBODY, Inc",
      desc: [
        "AWS: Worked on writing Lambda function, creating VPC's Cloud formation template, DynamoDB, RDS, IAM, Data pipeline and Redshift clusters.",
        "Architect/Design ETL to merge 100,000 Databases into Central Database in AWS RedShift cluster by using Py, SSIS and Shell Scripting.",
        "Python to write complex ETL script and analytical function to perform QA and Testing AWS RedShift Database.",
        "API Integration from different data sources( SalesForce, Internal MB API, Stripe) with millions of data loaded."
      ],
    },
    {
      yearRange: "August 2016 - November 2017",
      title: "Technology Analyst",
      place: "Infosys",
      desc: [
        "ETL (Oracle): Automated 80% of ETL code from scratch using PL/SQL. Design and Transforming Data from Legacy system into the Landing-Staging-Target. (Data Marts, Oracle PL/SQL)",
        "Requirement Gathering and reporting for LOB on daily basis. - (BRD, Data Lineage, conceptual, Logical, physical model and creating interactive ad-hoc Tableau report)",
        "Data modeling: Designing Conceptual, Logical and Physical Model on Mortgage Foreclosure domain (Power Designer).",
      ],
    },
    {
      yearRange: "April 2015 - April 2016",
      title: "Research Analyst",
      place: "Florida International University",
      desc: [
        "Contributed and analyzed all the business requirements for this project and managed a funding for $11m approx. as a team.",
        "Bus arrival and Parking space optimization - Created dashboard for Notification, ETA & Parking garage occupancy to monitor real-time status, Implemented predictive algorithm for real-time Bus arrival & Parking space (Python) and Reduced API",
        "Designed & built Database (RDBMS in MySQL), Backend Services (Python, SQL), RESTful API(Spring)"
      ],
    },
    {
      yearRange: "June 2011 - December 2014",
      title: "System Engineer ( Business Data Analyst)",
      place: "Infosys",
      desc: [
        "Worked on requirements gathering, integration and migration projects for Oracle 11i to R12 for AR, AP and GL modules.",
        "Developed Reports in PO, Financials and Inventory (Delivery Slip Report, PO Report, Custom PO delivery slip report)",
        "Data warehouse implementation for Wealth Management Company SSIS, SSMS, SSAS and Tableau.",
        "Help on Code Deployment, Testing, Quality assurance,Code Review and Defects fixed"
      ],
    },
  ];

  const educationDetails = [
    {
      yearRange: "August 2007 - April 2011",
      title: "Bachelors in Computer Science",
      place: "Narsee Monjee Institue of management Studies University",
      desc: "Completed my Bachelor's in Computer Science with distinction, showcasing dedication, expertise, and a passion for excellence in every endeavor.",
    },
    {
      yearRange: "January 2015 - March 2016",
      title: "Masters in Information Systems",
      place: "Florida International University",
      desc: "Successfully completed my Masters in Information Systems with exemplary achievement, demonstrating a commitment to excellence and a deep understanding of cutting-edge technologies and methodologies.",
    },
  ];

  const skills = [
    {
      name: "Data Engineering",
      percent: 90,
    },
    {
      name: "SQL",
      percent: 80,
    },
    {
      name: "Python",
      percent: 80,
    },
    {
      name: "Cloud Engineering",
      percent: 85,
    },
    {
      name: "Google Cloud Platform",
      percent: 80,
    },
    {
      name: "Data Modeling/Manupilation",
      percent: 95,
    },
    {
      name: "Azure",
      percent: 85,
    },
    {
      name: "Shell Scripting",
      percent: 80,
    },
    {
      name: "AWS",
      percent: 95,
    },
    {
      name: "Apache Airflow",
      percent: 75,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Experience */}
          <div className="col-md-12">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            <div className="row">
              {experienceDetails.map((value, index) => (
                <div key={index} className="col-md-6">
                  <div
                    className={
                      "bg-white  rounded p-4 mb-4 " +
                      (darkTheme ? "bg-dark" : "bg-white border")
                    }
                  >
                    <p className="badge bg-primary text-2 fw-400">
                      {value.yearRange}
                    </p>
                    <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                      {value.title}
                    </h3>
                    <p className={darkTheme ? "text-primary" : "text-danger"}>
                      {value.place}
                    </p>
                    {Array.isArray(value.desc) ? (
                      <ul>
                        {value.desc.map((point, index) => (
                          <li key={index}>{point}</li>
                        ))}
                      </ul>
                    ) : (
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {value.desc}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* My Education */}
        <div className="row gx-5">
          <div className="col-md-12">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href="http://216.48.185.242/NitinResume.pdf"
            target="_blank"
            download
          >
            Resume
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
