import React from "react";

import Slider from "react-slick";

const Testimonials = ({ classicHeader, darkTheme }) => {
  const reviews = [
    {
      name: "Jose Castillo",
      position: "Developer, Florida internatinal University",
      src: "images/testimonial/client-sm-3.jpg",
      desc: `Nitin is an incredibly sharp, passionate, and dedicated teammate. I had the pleasure of working
      with him as a student and research assistant for more than one year at Florida International
      University. He is very focused and has the fortitude to overcome obstacles on any initiative set
      out. His desire to constantly improve awarded him for designing the optimal predictive model to
      get the highest profit on a campaign. I hope to get the chance to work with him again.`,
      rating: 5,
    },
    {
      name: "Gerald Inberg",
      position: "Manager, ITPA",
      src: "images/testimonial/client-sm-1.jpg",
      desc: `Nitin Jain worked on the Informed Traveler Program and Applications (ITPA) project as a research
      assistant since the spring of 2015. During his time with me, Nitin worked on the ITPA core,
      developing features using Java and Python and in the front end work on the Smart Phone and
      Operations Center working in HTML5, CSS, and JavaScript. We do our development work as an agile
      scrum team and he works very well with the team in this mode, jumping in where he can help,
      contributing his ideas, and promoting team values. He is always willing to take on new
      challenges and has now jumped in to work on an embedded device on transit vehicles gathering
      data for ITPA. In summary, Nitin is friendly, enthusiastic, and provides good quality code. He
      would be an asset to any team.`,
      rating: 5,
    },
    {
      name: "Ankoor Desai,",
      position: "Business Analyst, UAIG",
      src: "images/testimonial/client-sm-2.jpg",
      desc: `I am currently attending Masters classes with Nitin at Florida International University. Nitin is
      a very self-motivated and hardworking individual who also has a great sense of humor. He has
      extensive experience in development and information systems and has proven himself to be a hard
      worker. Nitin has incredible potential and would make a great asset to any organization.`,
      rating: 5,
    },
    {
      name: "Vaishnavi Preethi",
      position: "Consultant, SDBC",
      src: "images/testimonial/client-sm-4.jpg",
      desc: `I have known Nitin for more than a year now, in this timeframe I saw him not only as a hard
      worker but also a very good contributor to the team, he demonstrates not only mastery at the
      core skills but also goes beyond to take new initiatives, which could be critical to the
      project/organization. He has the mix of both productivity and ambition set, thereby setting a
      good example to the team and that is why everyone loves working with Nitin. I highly recommend
      Nitin for his Capabilities, Abilities and Strong passion for technologies.`,
      rating: 4,
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section
      id="testimonial"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Testimonial
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Client Speak
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((value, index) => (
              <div className="item" key={index}>
                <div
                  className={
                    " rounded p-5 " + (darkTheme ? "bg-dark" : "bg-light")
                  }
                >
                  <div className="d-flex align-items-center mt-auto mb-4">
                    <img
                      className="img-fluid rounded-circle border d-inline-block w-auto"
                      src={value.src}
                      alt=""
                    />
                    <p className="ms-3 mb-0">
                      <strong
                        className={
                          "d-block fw-600 " +
                          (darkTheme ? "text-white" : "text-dark")
                        }
                      >
                        {value.name}
                      </strong>
                      <span className="text-muted fw-500">
                        {" "}
                        {value.position}{" "}
                      </span>
                    </p>
                  </div>
                  <p
                    className={
                      " fw-500 mb-4 " + (darkTheme ? "text-white" : "text-dark")
                    }
                  >
                    “{value.desc}”
                  </p>
                  <span className="text-2">
                    {[...Array(value.rating)].map((value, i) => (
                      <i className="fas fa-star text-warning" key={i} />
                    ))}
                  </span>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
