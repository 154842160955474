import React from "react";

const Disclaimer = ({ darkTheme }) => {
  return (
    <div
      id="disclaimer"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Copyright &amp; Disclaimer
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <p>
              The following materials are provided "as is" and without
              warranties of any kind, either express or implied. The use of
              these materials is at your own risk, and I disclaim all
              warranties, express or implied, including but not limited to,
              implied warranties of merchantability and fitness for a particular
              purpose. I do not warrant that the functions contained in the
              materials will be uninterrupted or error-free, that defects will
              be corrected, or that this site or the server that makes it
              available are free of viruses or other harmful components. I do
              not warrant or make any representations regarding the use or the
              results of the use of the materials on this site in terms of their
              correctness, accuracy, reliability, or otherwise. You (and not I)
              assume the entire cost of all necessary servicing, repair, or
              correction. Applicable law may not allow the exclusion of implied
              warranties, so the above exclusion may not apply to you.
            </p>
            <ul className="lh-lg">
              <li>
                Limitation of Liability: Under no circumstances, including, but
                not limited to, negligence, shall I be liable for any special or
                consequential damages that result from the use of, or the
                inability to use, the materials on this site, even if I have
                been advised of the possibility of such damages. Applicable law
                may not allow the limitation or exclusion of liability for
                incidental or consequential damages, so the above limitation or
                exclusion may not apply to you. In no event shall my total
                liability to you for all damages, losses, and causes of action
                (whether in contract, tort, including but not limited to,
                negligence or otherwise) exceed the amount paid by you, if any,
                for accessing this site.
              </li>
              <li>
                Indemnification: You agree to defend, indemnify, and hold
                harmless myself, my affiliates, and their respective officers,
                directors, employees, and agents from and against any and all
                claims, liabilities, damages, losses, or expenses, including
                reasonable attorneys' fees and costs, arising out of or in any
                way connected with your access to or use of this site.
              </li>
              <li>
                Ownership of Content: All content included on this site, such as
                text, graphics, logos, button icons, images, audio clips,
                digital downloads, data compilations, and software, is the
                property of Nitin Jain or its content suppliers and protected by
                international copyright laws. The compilation of all content on
                this site is the exclusive property of Nitin Jain, with
                copyright authorship for this collection by Nitin Jain, and
                protected by international copyright laws.
              </li>
              <li>
                Permission for Use: You may download, print, or copy the
                materials for personal or internal business use only. Any other
                use without express written consent from Nitin Jain is strictly
                prohibited.
              </li>
              <br />
              <p>
                This disclaimer template is a general guide and may need to be
                tailored to fit specific circumstances or legal requirements.
                It's recommended to consult with a legal professional for advice
                on creating a disclaimer suitable for your particular situation
              </p>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
